export const addCarouselBtnClicks = () => {
    const hiddenInternalPrevBtn = document.querySelector('#natrelle-patients-carousel button[data-controls="prev"]') as HTMLButtonElement;
    const hiddenInternalNextBtn = document.querySelector('#natrelle-patients-carousel button[data-controls="next"]') as HTMLButtonElement;
    window.Bus.on('emu-button:click', ({ id }) => {
        if (id === 'carousel-button-prev') {
            hiddenInternalPrevBtn?.click();
        }
        if (id === 'carousel-button-next') {
            hiddenInternalNextBtn?.click();
        }
    })
}