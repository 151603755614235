export const addLabelAsPlacholder = () => {
    const requiredTextInputs = document.querySelectorAll('.emu-form-text[data-required="true"], .emu-form-textarea[data-required="true"], .social-input');
    requiredTextInputs.forEach(input => {
      const labelEl = input.querySelector('label');
      const inputEl = input.querySelector('input, textarea') as HTMLInputElement;
      labelEl?.addEventListener('click', () => {
        inputEl?.focus();
      });
      inputEl?.addEventListener('focus', () => {
        labelEl?.classList.add('js-hidden');
      })

      inputEl?.addEventListener('blur', () => {
        if (inputEl.value.length == 0) {
          labelEl?.classList.remove('js-hidden');
        } 
      })
      inputEl?.blur();
    })
}