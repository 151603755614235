import { waitForElement } from "./waitForElement";

class ClassWatcher {
    targetNode: any;
    classToWatch: any;
    classAddedCallback: Function;
    classRemovedCallback: Function;
    observer: MutationObserver | null;
    lastClassState: string;

    constructor(targetNode, classToWatch, classAddedCallback, classRemovedCallback) {
        this.targetNode = targetNode
        this.classToWatch = classToWatch
        this.classAddedCallback = classAddedCallback
        this.classRemovedCallback = classRemovedCallback
        this.observer = null
        this.lastClassState = targetNode.classList.contains(this.classToWatch)

        this.init()
    }

    init() {
        this.observer = new MutationObserver(this.mutationCallback)
        this.observe()
    }

    observe() {
        this.observer?.observe(this.targetNode, { attributes: true })
    }

    disconnect() {
        this.observer?.disconnect()
    }

    mutationCallback = mutationsList => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                let currentClassState = mutation.target.classList.contains(this.classToWatch)
                if(this.lastClassState !== currentClassState) {
                    this.lastClassState = currentClassState
                    if(currentClassState) {
                        this.classAddedCallback()
                    }
                    else {
                        this.classRemovedCallback()
                    }
                }
            }
        }
    }
}

export const handleRecaptcha = (validRequiredInputs, setButtonState) => { 
    const targetNode = document.querySelector('#tell-us-form [data-component="recaptcha"] input') as HTMLInputElement;
    if (targetNode) {
        const config = { attributes: true, attributeFilter: ['value'] };

        const callback = (mutationList) => {
        for (const mutation of mutationList) {
            if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
                validRequiredInputs[targetNode.name] = !!targetNode.value;
                setButtonState();
            }
        }
        };

        const observer = new MutationObserver(callback);
        observer.observe(targetNode, config);
    }
}

export const handleInputs = () => {
    let validRequiredInputs = {};
    const formButton = document.querySelector('#tell-us-form .emu-form-button') as HTMLButtonElement;
    formButton.disabled = true;

    // determines if all required inputs are valid, and disables/enables button accordingly
    const setButtonState = () => {
        const isFormValid = Object.values(validRequiredInputs).reduce((acc, current) => {
            return acc && current
        });
        formButton.disabled = !isFormValid;
    }

    document.querySelectorAll('#tell-us-form input[aria-required="true"], #tell-us-form textarea[aria-required="true"], #tell-us-form fieldset[aria-required="true"], #tell-us-form [data-component="recaptcha"] input').forEach(el => {
        // sets initial validation state;
        const input = el as HTMLInputElement || HTMLTextAreaElement || HTMLFieldSetElement;
        validRequiredInputs[input.name] = false;
    

        // uses validation class to determin validation state
        const onClassAdd = () => {
            validRequiredInputs[input.name] = !(input.classList.contains('js-validation-failed') || input.classList.contains('js-has-error'));
            setButtonState();
        }
        const onClassRemoval = () => {
            validRequiredInputs[input.name] = !(input.classList.contains('js-validation-failed') || input.classList.contains('js-has-error'));
            setButtonState();
        }

        new ClassWatcher(input, 'js-validation-failed', onClassAdd, onClassRemoval);
        new ClassWatcher(input, 'js-has-error', onClassAdd, onClassRemoval);
        // added to trigger check if autofilled when no error/validation class is added/removed 
        new ClassWatcher(input, 'input-changed', onClassAdd, onClassRemoval);
    });
    
    // added to trigger check if autofilled when no error/validation class is added/removed 
    window.Bus.on(`emu-form-text:change`, ({ required, input }) => {
        if (required && validRequiredInputs.hasOwnProperty(input.name)) {
            input.classList.add('input-changed');
        }
    });

    // added to trigger check if autofilled when no error/validation class is added/removed 
    window.Bus.on(`emu-form-textarea:change`, ({ required, textarea }) => {
        if (required && validRequiredInputs.hasOwnProperty(textarea.name)) {
            textarea.classList.add('input-changed');
        }
    });

    // for dropdown when autofilled to correct visible value
    waitForElement('select[name="state"]').then(elm => {
        const targetNode = elm as HTMLElement;
        targetNode.addEventListener('change', (e) => {
            const target = e.target as HTMLSelectElement;
            const realValue = target.value;
            const visibleValue = (document.querySelector('#state-select .emu-dropdown-menu__button span') as HTMLElement)?.innerText;
            if (realValue !== visibleValue) {
                const selected = Array.from(document.querySelectorAll('#state-select .emu-dropdown-menu__group li')).find((el) => {
                    return el.innerHTML === realValue;
                }) as HTMLElement;
                selected.click();
            }
        })
    });

    // for dropdown
    window.Bus.on(`emu-form-select:change`, ({ id, required, selectOptions }) => {
        const selected = selectOptions.find((el) => el.selected);
        
        if (selected) {
            document.querySelector(`#${id}`)?.classList.add('js-option-selected')
        }

        if (required && validRequiredInputs.hasOwnProperty(selected.name)) {
            validRequiredInputs[selected.name] = selected.value !== 'null';
            setButtonState();
        }
    });

    const handelRadioHideShows = (checkedEl, valueForVisisbility, hideshowIputClass) => {
        const surgeonElements = document.querySelectorAll(`.${hideshowIputClass}`);
        surgeonElements?.forEach(el => {
            const element = el as HTMLElement;
            if (checkedEl.value === valueForVisisbility) {
                element.classList.remove('js-hidden')
            } else {
                element.classList.add('js-hidden');
                if (element.classList.contains('emu-form-text') || element.classList.contains('emu-form-textarea')) {
                    const input = element.querySelector('input, textarea') as HTMLInputElement;
                    input.value = "";
                }
            }
        })
    }

    window.Bus.on(`emu-form-radio:change`, ({ radios }) => {
        const fieldName = radios[0].name;
        const checked = radios.find((el) => el.checked);
        if (fieldName === 'haveImplants') {
            handelRadioHideShows(checked, 'Yes', 'plastic-surgeon-el');
            handelRadioHideShows(checked, 'Yes', 'procedure-date-el');
        } else if (fieldName === 'castingCall') {
            handelRadioHideShows(checked, 'Surgeon', 'referral-surgeon-el');
            handelRadioHideShows(checked, 'Other', 'referral-other-el');
        }
    });

    document.querySelectorAll('#tell-us-form fieldset[data-required=true]')?.forEach(el => {
        el.addEventListener('change', (e) => {
        const fieldSet = e.target as HTMLFieldSetElement;
            validRequiredInputs[fieldSet.name] = !fieldSet['data-invalid'];
            setButtonState();
        });
    });

    handleRecaptcha(validRequiredInputs, setButtonState);
}

