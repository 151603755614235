const _temp0 = require("./components/card/_card.scss");

const _temp1 = require("./components/container-isi/_container-isi.scss");

const _temp2 = require("./components/carousel/_carousel.scss");

const _temp3 = require("./components/cookie-consent-banner/_cookie-consent-banner.scss");

const _temp4 = require("./components/footer/_footer.scss");

const _temp5 = require("./components/form/_form.scss");

const _temp6 = require("./components/header/_header.scss");
const _temp7 = require("./components/header/_nav.scss");

const _temp8 = require("./components/modal/modal.scss");

const _temp9 = require("./components/teaser/_teaser.scss");

const _temp10 = require("./components/text/_text.scss");

const _temp11 = require("./components/title/_title.scss");

module.exports = {
  "card":   {
    "_card": _temp0
  },
  "container-isi":   {
    "_container-isi": _temp1
  },
  "carousel":   {
    "_carousel": _temp2
  },
  "cookie-consent-banner":   {
    "_cookie-consent-banner": _temp3
  },
  "footer":   {
    "_footer": _temp4
  },
  "form":   {
    "_form": _temp5
  },
  "header":   {
    "_header": _temp6,
    "_nav": _temp7
  },
  "modal":   {
    "modal": _temp8
  },
  "teaser":   {
    "_teaser": _temp9
  },
  "text":   {
    "_text": _temp10
  },
  "title":   {
    "_title": _temp11
  }
}