// Stylesheets
import './main.scss';

import './resources/images/favicon.png';
import { formatDateInput } from './components/form/options/options'
import { addLabelAsPlacholder } from './components/form/text/text'
import { handleInputs } from './components/form/form';
import { addCloseOnOffclicks } from './components/header/header';
import { addCarouselBtnClicks } from './components/carousel/carousel'
// Imports all component based scss files.
import './components/**/*.scss';
// import favicon here.

interface AAAEM {
  isMobile: boolean;
  isTouch: boolean;
  isAuthorMode: boolean,
  env: string,
  browser: {
    name: string;
    version: string;
  },
  // All utility functions can be found here: https://gitlab.com/venture-moonwalker/aaaem/aaaem-common/-/blob/645c2f4310c2cf0864454035850a95d28e65e68e/ui.frontend/src/global.ts
  utilities: any;
  plugins: {
    tinySlider: any;
    badgerAccordion: any;
    tabbyJs: any;
    plyr: any;
  }
}

declare global {
  interface Window {
    AAAEM: AAAEM;
    Bus: any; // event bus
    OneTrust: any;
    _tnsInstances: any; //carousel
    _tabbyInstances: any; //tabs
    _plyrInstances: any; //vimeo embed 
    _badgerInstances: any; //accordion
  }
}

(() => {
  const init = () => {
    addLabelAsPlacholder();
    addCarouselBtnClicks();
    formatDateInput();
    handleInputs();

    // stops screen scroll when nav modal open
    const navButton = document.querySelector('.nav__button') as HTMLElement;
    navButton?.addEventListener('click', () => {
      if (navButton?.classList.contains('js-toggle-on')) {
        document.body.classList.add('js-scroll-disabled');
      } else {
        document.body.classList.remove('js-scroll-disabled');
      }
    })

    // close nav on link click
    document.querySelectorAll('.emu-navigation__menu--level-0 li > a, .additional-links a').forEach(el => {
      el?.addEventListener('click', () => {
        navButton?.click();
      })
    })

    // hides/shows additional privacy notice content
    const expandedContent = document.querySelectorAll('.expanded-content');
    const expandText = document.querySelector('.expand-text');
    expandText?.addEventListener('click', (e) => {
      expandedContent.forEach(el => {
        el.classList.remove('js-hidden');
      })
      const { target } = e;
      if (target instanceof HTMLElement)  {
        target?.classList.add('js-hidden'); 
      }
    })
    document.querySelector('.collapse-text')?.addEventListener('click', (e) => {
      expandedContent.forEach(el => {
        el.classList.add('js-hidden');
      })
      const { target } = e;
      if (target instanceof HTMLElement)  {
        expandText?.classList.remove('js-hidden'); 
      }
    })

    // add css class to outer text element for partent styling 
    document.querySelectorAll('.half-width--desktop').forEach(childEl => {
      const parentEl = childEl.closest('.text');
      parentEl?.classList.add('js-half-width--desktop');
    })

    document.querySelectorAll('.third-width--desktop').forEach(childEl => {
      let parentEl;
      if (childEl.classList.contains('emu-form-text')) {
        parentEl = childEl.closest('.text');
      } else if (childEl.classList.contains('emu-form-dropdown')) {
        parentEl = childEl.closest('.options');
      }
      parentEl?.classList.add('js-third-width--desktop');
    })

    const handleSubmit = () => {;
      document.querySelectorAll('#tell-us-form label').forEach(el => {
        const label = el as HTMLElement;
        label.classList.remove('js-hidden');
      })
    }

    if (window.Bus) {
      addCloseOnOffclicks();
      window.Bus.on(`emu-form:submit`, () => {
        handleSubmit();
        const modalTrigger = document.querySelector('#success-modal-trigger') as HTMLButtonElement;
        modalTrigger?.click();
      });

      //trigger document click on button click, for dropdowns
      window.Bus.on('emu-button:click', () => {
        document.dispatchEvent( new Event('click') );
      });
    }
  };
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', init);
  } else {
    init();
  }
})(); 