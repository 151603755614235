export const addCloseOnOffclick = (className) => {
    // Closes for Patients dropdown on offclick
    const dropdown = document.querySelector(`.${className}`);
    const titleWrapper = dropdown?.querySelector('.emu-card__title-wrapper') as HTMLElement;
    window.addEventListener('mousedown', (event) => {
      const target = event.target as Node;
      const isDropdownClick = titleWrapper.contains(target);
      if (!isDropdownClick) {
        const dropdownBtn = dropdown?.querySelector('.emu-card__toggle-cta button') as HTMLButtonElement;
        if (dropdownBtn.classList.contains('js-toggle-on')) {
          titleWrapper?.click();
        }
      }
    })

    // trigger dropdown close on link click
    dropdown?.querySelectorAll('a').forEach(link => {
      link.addEventListener('click', () => {
        titleWrapper?.click();
      });
    })
}

export const addCloseOnOffclicks = () => {
    addCloseOnOffclick('for_patients_dropdown');
    addCloseOnOffclick('all_brands');
}