export const waitForElement = (selector: string, parent = document) => {
  return new Promise(resolve => {
    if (parent.querySelector(selector)) {
      return resolve(parent.querySelector(selector));
    }
    const observer = new MutationObserver(() => {
      if (parent.querySelector(selector)) {
        resolve(parent.querySelector(selector));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};
